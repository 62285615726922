@font-face {
  font-family: 'iconofont';
  src:  url('fonts/iconofont.eot?dmtf1k');
  src:  url('fonts/iconofont.eot?dmtf1k#iefix') format('embedded-opentype'),
    url('fonts/iconofont.ttf?dmtf1k') format('truetype'),
    url('fonts/iconofont.woff?dmtf1k') format('woff'),
    url('fonts/iconofont.svg?dmtf1k#iconofont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconofont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checklist:before {
  content: "\e9fa";
}
.icon-scheduling-outline:before {
  content: "\e9fb";
}
.icon-scheduling-solid:before {
  content: "\e9fc";
}
.icon-campaign-outline:before {
  content: "\e9f3";
}
.icon-campaign-solid:before {
  content: "\e9f4";
}
.icon-image-arrow-up-outline:before {
  content: "\e9f5";
}
.icon-image-arrow-up-solid:before {
  content: "\e9f7";
}
.icon-inspiration-solid:before {
  content: "\e9f8";
}
.icon-inspiration-outline:before {
  content: "\e9f9";
}
.icon-clipboard-check:before {
  content: "\e9f6";
}
.icon-account-emoji-outline:before {
  content: "\e9ee";
}
.icon-rocket-outline:before {
  content: "\e9ef";
}
.icon-gem-outline:before {
  content: "\e9f0";
}
.icon-briefcase-outline:before {
  content: "\e9f1";
}
.icon-crown-outline:before {
  content: "\e9f2";
}
.icon-crown:before {
  content: "\e9c1";
}
.icon-alert:before {
  content: "\e9ed";
}
.icon-gif:before {
  content: "\e9ec";
}
.icon-card-impressions:before {
  content: "\e9e8";
}
.icon-forward:before {
  content: "\e9e7";
}
.icon-sidebar:before {
  content: "\e9e6";
}
.icon-alt-text:before {
  content: "\e9e2";
}
.icon-plus-border:before {
  content: "\e9e3";
}
.icon-account:before {
  content: "\e9e4";
}
.icon-account-on:before {
  content: "\e9e5";
}
.icon-soon:before {
  content: "\e9e1";
}
.icon-users-roles-on:before {
  content: "\e93a";
}
.icon-users-roles:before {
  content: "\e943";
}
.icon-group-on:before {
  content: "\e9c3";
}
.icon-group:before {
  content: "\e9c4";
}
.icon-single-profile-on:before {
  content: "\e9df";
}
.icon-single-profile:before {
  content: "\e9e0";
}
.icon-completion-rate:before {
  content: "\e9de";
}
.icon-edit-sparkle:before {
  content: "\e9dd";
}
.icon-calendar-add-on:before {
  content: "\e9dc";
}
.icon-revert:before {
  content: "\e9d9";
}
.icon-inspiration:before {
  content: "\e9da";
}
.icon-inspiration-on:before {
  content: "\e9db";
}
.icon-dot:before {
  content: "\e9d8";
}
.icon-tools:before {
  content: "\e9d7";
}
.icon-tab-views:before {
  content: "\e9ce";
}
.icon-page-checkins:before {
  content: "\e9cf";
}
.icon-page-consumptions:before {
  content: "\e9d0";
}
.icon-page-positive-actions:before {
  content: "\e9d1";
}
.icon-page-views:before {
  content: "\e9d2";
}
.icon-post-it:before {
  content: "\e9d3";
}
.icon-section:before {
  content: "\e9d4";
}
.icon-cross-posting:before {
  content: "\e9cc";
}
.icon-content-approval:before {
  content: "\e9cd";
}
.icon-feed-preview:before {
  content: "\e9cb";
}
.icon-sort-by:before {
  content: "\e9ca";
}
.icon-dark:before {
  content: "\e9c5";
}
.icon-light:before {
  content: "\e9c7";
}
.icon-audio:before {
  content: "\e9c6";
}
.icon-notes:before {
  content: "\e9c0";
}
.icon-text-list:before {
  content: "\e9be";
}
.icon-text-stroke:before {
  content: "\e9bf";
}
.icon-sneak-peak:before {
  content: "\e9bc";
}
.icon-column:before {
  content: "\e9bb";
}
.icon-exclamation-point:before {
  content: "\e9ba";
}
.icon-hand-share:before {
  content: "\e9b7";
}
.icon-preview-off:before {
  content: "\e9b8";
}
.icon-preview:before {
  content: "\e9b9";
}
.icon-text-align-right:before {
  content: "\e9a7";
}
.icon-text-align-center:before {
  content: "\e9a8";
}
.icon-text-align-left:before {
  content: "\e9a9";
}
.icon-text-underline:before {
  content: "\e9aa";
}
.icon-text-italic:before {
  content: "\e9ab";
}
.icon-text-bold:before {
  content: "\e9ac";
}
.icon-dashboard-report-end:before {
  content: "\e9ad";
}
.icon-dashboard-report-cover:before {
  content: "\e9ae";
}
.icon-zoom-out:before {
  content: "\e9af";
}
.icon-zoom-in:before {
  content: "\e9b0";
}
.icon-dashboard-report-body:before {
  content: "\e9b1";
}
.icon-magic-wand:before {
  content: "\e9b2";
}
.icon-sparkles:before {
  content: "\e9bd";
}
.icon-size-font:before {
  content: "\e9b3";
}
.icon-performance:before {
  content: "\e9a6";
}
.icon-email-clicks:before {
  content: "\e9a1";
}
.icon-get-direction-clicks:before {
  content: "\e9a2";
}
.icon-phone-calls:before {
  content: "\e9a3";
}
.icon-text-message-clicks:before {
  content: "\e9a4";
}
.icon-website-clicks:before {
  content: "\e9a5";
}
.icon-product-updates:before {
  content: "\e9a0";
}
.icon-industry-benchmark:before {
  content: "\e99d";
}
.icon-feeds:before {
  content: "\e99e";
}
.icon-competitors:before {
  content: "\e99f";
}
.icon-saved:before {
  content: "\e99c";
}
.icon-media-library:before {
  content: "\e99b";
}
.icon-collaboration:before {
  content: "\e99a";
}
.icon-reports:before {
  content: "\e998";
}
.icon-reports-on:before {
  content: "\e999";
}
.icon-validate-app:before {
  content: "\e995";
}
.icon-double-chevron-left:before {
  content: "\e996";
}
.icon-double-chevron-right:before {
  content: "\e997";
}
.icon-tiktok:before {
  content: "\e994";
}
.icon-location:before {
  content: "\e993";
}
.icon-media-upload:before {
  content: "\e991";
}
.icon-locked:before {
  content: "\e98e";
}
.icon-category:before {
  content: "\e98f";
}
.icon-crossposts:before {
  content: "\e990";
}
.icon-reorganize:before {
  content: "\e986";
}
.icon-crop:before {
  content: "\e987";
}
.icon-crop-2-1:before {
  content: "\e988";
}
.icon-crop-4-3:before {
  content: "\e989";
}
.icon-crop-4-5:before {
  content: "\e98a";
}
.icon-crop-3-4:before {
  content: "\e98b";
}
.icon-crop-square:before {
  content: "\e98c";
}
.icon-crop-free:before {
  content: "\e98d";
}
.icon-captions:before {
  content: "\e985";
}
.icon-duplicate:before {
  content: "\e984";
}
.icon-versus:before {
  content: "\e982";
}
.icon-interface-drag:before {
  content: "\e980";
}
.icon-retweet:before {
  content: "\e97e";
}
.icon-rejected:before {
  content: "\e97c";
}
.icon-scheduled:before {
  content: "\e97d";
}
.icon-text:before {
  content: "\e97f";
}
.icon-hashtag:before {
  content: "\e973";
}
.icon-dots:before {
  content: "\e974";
}
.icon-autopost:before {
  content: "\e975";
}
.icon-crosspost:before {
  content: "\e976";
}
.icon-autopost-no:before {
  content: "\e977";
}
.icon-feed:before {
  content: "\e978";
}
.icon-time-slots:before {
  content: "\e979";
}
.icon-calendar-add:before {
  content: "\e97a";
}
.icon-like-reaction:before {
  content: "\e96d";
}
.icon-love-reaction:before {
  content: "\e96e";
}
.icon-wow-reaction:before {
  content: "\e96f";
}
.icon-haha-reaction:before {
  content: "\e970";
}
.icon-sad-reaction:before {
  content: "\e971";
}
.icon-angry-reaction:before {
  content: "\e972";
}
.icon-document:before {
  content: "\e967";
}
.icon-poll:before {
  content: "\e968";
}
.icon-completed:before {
  content: "\e969";
}
.icon-refresh:before {
  content: "\e965";
}
.icon-plus-sign:before {
  content: "\e964";
}
.icon-story:before {
  content: "\e95c";
}
.icon-reel:before {
  content: "\e95d";
}
.icon-igtv:before {
  content: "\e95e";
}
.icon-video:before {
  content: "\e95f";
}
.icon-labels:before {
  content: "\e960";
}
.icon-albums:before {
  content: "\e961";
}
.icon-link:before {
  content: "\e962";
}
.icon-carousel:before {
  content: "\e963";
}
.icon-exit:before {
  content: "\e957";
}
.icon-share:before {
  content: "\e958";
}
.icon-replies:before {
  content: "\e959";
}
.icon-reaction:before {
  content: "\e95a";
}
.icon-dislike:before {
  content: "\e9e9";
}
.icon-click:before {
  content: "\e95b";
}
.icon-interface-list:before {
  content: "\e956";
}
.icon-warning:before {
  content: "\e933";
}
.icon-help:before {
  content: "\e952";
}
.icon-paid-data:before {
  content: "\e953";
}
.icon-organic-data:before {
  content: "\e954";
}
.icon-money:before {
  content: "\e955";
}
.icon-menu-burger:before {
  content: "\e951";
}
.icon-add-profiles:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e906";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-top:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-arrow-bottom:before {
  content: "\e929";
}
.icon-arrow-45-bottom:before {
  content: "\e910";
}
.icon-arrow-45-top:before {
  content: "\e921";
}
.icon-chevron-left:before {
  content: "\e913";
}
.icon-chevron-top:before {
  content: "\e911";
}
.icon-chevron-right:before {
  content: "\e912";
}
.icon-chevron-bottom:before {
  content: "\e924";
}
.icon-close:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e92d";
}
.icon-reduce:before {
  content: "\e914";
}
.icon-explore:before {
  content: "\e9c8";
}
.icon-explore-on:before {
  content: "\e9c9";
}
.icon-publishing:before {
  content: "\e915";
}
.icon-publishing-on:before {
  content: "\e916";
}
.icon-notification:before {
  content: "\e917";
}
.icon-notification-on:before {
  content: "\e918";
}
.icon-content:before {
  content: "\e919";
}
.icon-content-on:before {
  content: "\e91a";
}
.icon-posts:before {
  content: "\e91b";
}
.icon-albums-labels:before {
  content: "\e91c";
}
.icon-albums-add:before {
  content: "\e96b";
}
.icon-listening:before {
  content: "\e91d";
}
.icon-listening-on:before {
  content: "\e91e";
}
.icon-dashboard:before {
  content: "\e91f";
}
.icon-dashboard-on:before {
  content: "\e900";
}
.icon-conversations:before {
  content: "\e901";
}
.icon-conversations-on:before {
  content: "\e902";
}
.icon-analytics:before {
  content: "\e903";
}
.icon-analytics-on:before {
  content: "\e904";
}
.icon-comments:before {
  content: "\e920";
}
.icon-comments-on:before {
  content: "\e94a";
}
.icon-likes:before {
  content: "\e90b";
}
.icon-likes-on:before {
  content: "\e922";
}
.icon-engagement:before {
  content: "\e94d";
}
.icon-engagement-on:before {
  content: "\e94c";
}
.icon-visible:before {
  content: "\e945";
}
.icon-invisible:before {
  content: "\e944";
}
.icon-play:before {
  content: "\e935";
}
.icon-pause:before {
  content: "\e96a";
}
.icon-videos:before {
  content: "\e946";
}
.icon-multiprofiles:before {
  content: "\e934";
}
.icon-followers-up:before {
  content: "\e92a";
}
.icon-clock:before {
  content: "\e923";
}
.icon-calendar:before {
  content: "\e92b";
}
.icon-disconnect:before {
  content: "\e925";
}
.icon-doc:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e927";
}
.icon-upload:before {
  content: "\e90c";
}
.icon-export:before {
  content: "\e928";
}
.icon-filter:before {
  content: "\e94e";
}
.icon-goal:before {
  content: "\e93f";
}
.icon-grid:before {
  content: "\e92c";
}
.icon-ideas:before {
  content: "\e93d";
}
.icon-impression:before {
  content: "\e92e";
}
.icon-info:before {
  content: "\e92f";
}
.icon-list:before {
  content: "\e930";
}
.icon-logout:before {
  content: "\e931";
}
.icon-mentions:before {
  content: "\e932";
}
.icon-quotes:before {
  content: "\e936";
}
.icon-reply:before {
  content: "\e937";
}
.icon-search:before {
  content: "\e938";
}
.icon-settings:before {
  content: "\e939";
}
.icon-star:before {
  content: "\e93b";
}
.icon-stats:before {
  content: "\e93c";
}
.icon-stories1:before {
  content: "\e93e";
}
.icon-support:before {
  content: "\e940";
}
.icon-translate:before {
  content: "\e941";
}
.icon-trash:before {
  content: "\e942";
}
.icon-widgets:before {
  content: "\e948";
}
.icon-reach:before {
  content: "\e947";
}
.icon-eye:before {
  content: "\e950";
}
.icon-impressions:before {
  content: "\e94f";
}
.icon-followers:before {
  content: "\e949";
}
.icon-collections:before {
  content: "\e94b";
}
.icon-linkedin:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e966";
}
.icon-dm:before {
  content: "\e96c";
}
.icon-interface-paid-locked:before {
  content: "\e97b";
}
.icon-emojis:before {
  content: "\e981";
}
.icon-envelope:before {
  content: "\e983";
}
.icon-folder-open:before {
  content: "\e992";
}
.icon-trophy:before {
  content: "\e9b4";
}
.icon-reel-plays:before {
  content: "\e9b5";
}
.icon-reel-interactions:before {
  content: "\e9b6";
}
.icon-hourglass:before {
  content: "\e9c2";
}
.icon-kpi:before {
  content: "\e9d5";
}
.icon-graph:before {
  content: "\e9d6";
}
.icon-carousel-images:before {
  content: "\e9ea";
}
.icon-carousel-videos:before {
  content: "\e9eb";
}
.icon-check-circle-outline:before {
  content: "\ea0a";
}
.icon-check-circle:before {
  content: "\ea0b";
}
.icon-bubble-outline:before {
  content: "\ea1c";
}
.icon-bubble:before {
  content: "\ea1d";
}
.icon-bubbles-outline:before {
  content: "\ea1e";
}
.icon-bubbles:before {
  content: "\ea1f";
}
.icon-emoji-comment:before {
  content: "\ea25";
}
.icon-eye-hide:before {
  content: "\ea27";
}
.icon-flag:before {
  content: "\ea2c";
}
.icon-inbox:before {
  content: "\ea37";
}
.icon-mail-open:before {
  content: "\ea43";
}
.icon-translate-outline:before {
  content: "\ea7c";
}
